import React from 'react';
import { graphql } from 'gatsby';
import mq from '@/styles/mq';
import { SEO } from '@/components/atoms';
import { Layout } from '@/components/organisms';


import DevisContainer from '@/containers/Devis/DevisContainer';
import { PrismicDevisPage } from '@/domain/PrismicDevisPage';
import { PageContext } from '@/domain/PageContext';

interface DevisTemplateProps {
    data: {
        prismicDevisPage: PrismicDevisPage;
    };
    pageContext: PageContext;
}

export const query = graphql`
  query ($id: String!) {
    prismicDevisPage(id: { eq: $id }) {
      id
      lang
      alternate_languages {
        lang
        uid
      }
      data {
        meta_title
        meta_description
        devis_content {
          raw
        }
      }
    }
  }
`;
const DevisTemplate = ({ data, pageContext }: DevisTemplateProps): JSX.Element => {
    const { alternate } = pageContext;
    const { lang, uid, alternate_languages } = data?.prismicDevisPage;
    const { meta_title, meta_description } = data?.prismicDevisPage?.data;

    const selectlang = {
        lang,
        uid: uid,
        alternate_languages,
    };
    const seo = {
        title: meta_title,
        description: meta_description,
        lang: lang,
        url: `${lang}`
    }
    const linkAlternate = alternate?.map(data => ({
        lang: data?.lang,
        url: `${data?.lang}/${data?.uid}`,
    }))
    return (
        <Layout selectLang={selectlang}>
            <SEO lang={seo.lang} title={`${seo.title}`} url={seo.url} description={seo.description} alternate={linkAlternate} />
            <DevisContainer data={data} lang={lang} />
        </Layout>
    );
};

export default DevisTemplate;
