import React from 'react';
import styled from '@emotion/styled';

import { Banner, Content, ContactBox } from '@/components/molecules';
import DevisForm from '@/containers/Devis/DevisForm';
import { PrismicDevisPage } from '@/domain/PrismicDevisPage';

interface DevisContainerProps {
    data: {
        prismicDevisPage: PrismicDevisPage;
    };
    lang: string;
}

const DevisContainerRoot = styled.section``;

const DevisContainer = ({ data, lang }: DevisContainerProps): JSX.Element => {
    const { devis_content } = data?.prismicDevisPage?.data;

    const ContentData = {
        content: devis_content?.raw
    }

    return (
        <DevisContainerRoot>
            <Banner lang={lang} />
            <Content {...ContentData} />
            <DevisForm lang={lang} />
            <ContactBox lang={lang} />
        </DevisContainerRoot>
    );
};

export default DevisContainer;